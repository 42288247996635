$mydna-background: #fbfbfb;
$mydna-blue: #30b5e8;
$mydna-white: #fff;
$mydna-Helptext: #707070;
$mydna-error:  red;
$mydna-title: #E72076;
$control-width: 20rem;

.complimentary-container{
  text-align: center;
  margin: auto;
  margin-top: 20rem;
}
.content-container {
  width: 22rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

a:link {
  color: $mydna-blue;
}


.mydna-logo{
  margin: auto;
}

.app-bar{
  background-color: #081f2d !important;
}

.MuiFormControl-root{
  min-width: 21rem !important;
  margin-top: 30px !important;
}

.SubmitButton {
  color: $mydna-white;
  font-size: 18px !important;
  border-radius: 6px !important;
  width: 19rem;
  background-color: $mydna-blue !important;
  font-family: NowRegular !important;
  margin: 20px !important;
}

.password-help-text {
  font-size: 12px;
  letter-spacing: 0px;
  color: $mydna-Helptext;
  line-height: 14px;
}

.error {
  color: $mydna-error;
}

.mydna-primary-title {
  color: $mydna-title;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 2.4px;
  font-family: MydnaNowBlack;
  text-align: center;
}

.mydna-secondary-title{
  color: #000;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 24px;
  font-family: AvenirRoman;
  text-align: center;
}

.content-container{
  width: 22rem; 
  margin: auto; 
  background-color: $mydna-background;
  padding: 10px;
  margin-top: 50px;
}

.title {
  color: #959595;
  font-family: NowRegular;
  font-style: normal;
  font-size: 18px;
  line-height: 57.6px;
  text-transform: uppercase;
  margin: 0rem auto 0 auto;
  width: $control-width;
  letter-spacing: 1.8px;
  text-align: center;
}

.reset-link{
  color: $mydna-blue;
  font-size: 18px;
}