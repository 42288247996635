body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'AvenirBlack';
  src: local('AvenirBlack'), url(./fonts/avenir/black/AvenirLTStd-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'AvenirLight';
  src: local('AvenirLight'), url(./fonts/avenir/light/AvenirLTStd-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'AvenirHeavy';
  src: local('AvenirHeavy'), url(./fonts/avenir/heavy/AvenirLTStd-Heavy.ttf) format('truetype');
}

@font-face {
  font-family: 'AvenirRoman';
  src: local('AvenirRoman'), url(./fonts/avenir/roman/AvenirLTStd-Roman.ttf) format('truetype');
}

@font-face {
  font-family: 'MydnaNowBlack';
  src: local('MydnaNowBlack'), url(./fonts/now/black/mydna-now-black.ttf) format('truetype');
}

@font-face {
  font-family: 'NowRegular';
  src: local('NowRegular'), url(./fonts/now/regular/now-regular.ttf) format('truetype');
}

@font-face {
  font-family: 'NowThin';
  src: local('NowThin'), url(./fonts/now/thin/now-thin.ttf) format('truetype');
}
